import {
  AutocompleteGoogleDS,
  ButtonDS,
  GlobalIcon,
  InputDS,
  PredictiveSearchDS,
  SelectDS,
  SingleSelect,
} from "get-life-storybook-ts";
import numberFormat from "../../utils/Utils";
import { useContext, useEffect } from "react";
import {
  BeneficiaryFormI,
  BeneficiaryITContext,
} from "../../context/BeneficiaryIT.context";
import { DOMAIN } from "../../constants/Global";

const BeneficiaryForm = ({
  totalCapital,
  minPercentage,
  maxPercentage,
}: any) => {
  const {
    translate,
    currentBeneficiary,
    beneficiariesList,
    cityList,
    countryList,
    beneficiaryForm,
    updateBeneficiaryForm,
    beneficiaryFormError,
    clearBeneficiaryError,
    updateCurrentBeneficiaryCapital,
    isEditingCapital,
    setIsEditingCapital,
  } = useContext(BeneficiaryITContext);

  const genderOptions = [
    {
      label: translate("beneficiaries.questions.male"),
      value: "Male",
    },
    {
      label: translate("beneficiaries.questions.female"),
      value: "Female",
    },
  ];

  const sameResidenceOptions = [
    {
      value: "yes",
      text: "Sì",
      label: translate("beneficiaries.questions.yes"),
    },
    {
      value: "no",
      text: "No",
      label: translate("beneficiaries.questions.no"),
    },
  ];

  const getPercentageCapital = (percentage: number) => {
    if (totalCapital) {
      const _capital = (totalCapital / 100) * percentage;
      return _capital;
    }
    return 0;
  };

  const beneficiaryLength = (() => {
    if (beneficiaryForm.beneficiaryId) {
      return (
        beneficiariesList.findIndex(
          (b) => b.id === beneficiaryForm.beneficiaryId
        ) + 1
      );
    }
    return beneficiariesList.length + 1;
  })();

  useEffect(() => {
    if (beneficiaryForm.beneficiaryId) {
      setIsEditingCapital(true);
    } else {
      setIsEditingCapital(false);
    }
  }, []);

  return isEditingCapital ? (
    <div className="py-[24px] flex flex-col gap-[8px] max-w-full">
      <div className="pt-[24px] flex flex-row items-center gap-[8px]">
        <GlobalIcon
          iconName="SimpleUserIcon"
          color="var(--primary-color)"
          size="XS"
        />
        <div className="flex-1">
          <div className="flex flex-row items-center flex-1">
            <div className="flex flex-row items-end gap-[16px] flex-1">
              <div className="flex flex-col">
                <span className="text-[#7B7B7B] BodyS font-medium text-[12px]">
                  {translate("questions.beneficiaries.beneficiary")}{" "}
                  {beneficiaryLength}
                </span>
                <span className="text-[#424242] BodyL font-medium">
                  {beneficiaryForm.name} {beneficiaryForm.lastName}
                </span>
              </div>
              {currentBeneficiary.percentage &&
              !isNaN(currentBeneficiary.percentage) ? (
                <p className="BodyL text-[var(--primary-color)] font-bold">
                  {`${currentBeneficiary.percentage}% = ${numberFormat(
                    currentBeneficiary.capital!,
                    "capital"
                  )}`}
                </p>
              ) : null}
            </div>
            <GlobalIcon iconName="ChevronRight" size="XS" color="#424242" />
          </div>
        </div>
      </div>
      <div className="flex md:flex-row flex-col gap-[16px]">
        <InputDS
          label={translate("questions.beneficiares.assign.capital")}
          onChange={(event) => {
            let value = parseInt(event.target.value);
            if (value > maxPercentage) value = maxPercentage;
            updateCurrentBeneficiaryCapital({
              capital: getPercentageCapital(value),
              percentage: value,
            });
          }}
          type="number"
          value={(() => {
            if (currentBeneficiary.percentage) {
              const percentageInt = parseInt(
                currentBeneficiary.percentage.toString()
              );
              if (isNaN(percentageInt)) return "";
              return percentageInt;
            }
            return "";
          })()}
          onBlur={(event) => {
            let value = parseInt(event.target.value);
            if (value > maxPercentage) value = maxPercentage;
            if (value < minPercentage) value = minPercentage;
            updateCurrentBeneficiaryCapital({
              capital: getPercentageCapital(value),
              percentage: value,
            });
          }}
          icon="PercentageIcon"
          placeholder={translate(
            "questions.beneficiares.assign.capital.placeholder"
          )}
          helper={translate("questions.beneficiares.assign.capital.helper")}
          min={minPercentage}
          max={maxPercentage}
          id="percentage-input-ref"
        />
        <ButtonDS
          label="Salvare"
          buttonType="tertiary"
          rightIcon="ArrowRightIcon"
          className="h-fit md:mt-[32px] w-full md:w-fit"
          disabled={
            !currentBeneficiary.percentage ||
            isNaN(currentBeneficiary.percentage)
          }
          onClick={() => {
            updateBeneficiaryForm(
              "capitalPercentage",
              currentBeneficiary.percentage
            );
            setIsEditingCapital(false);
          }}
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="py-[24px] flex flex-row items-center gap-[8px]">
        <GlobalIcon
          iconName="SimpleUserIcon"
          color="var(--primary-color)"
          size="XS"
        />
        <div className="flex-1">
          <div className="flex flex-row items-center flex-1">
            <div className="flex flex-row gap-[16px] flex-1">
              <p className="BodyL text-[#424242] font-medium">
                {`${translate(
                  "questions.beneficiaries.beneficiary"
                )} ${beneficiaryLength}`}
              </p>
              <p className="BodyL text-[var(--primary-color)] font-bold">
                {`${currentBeneficiary.percentage}% = ${numberFormat(
                  currentBeneficiary.capital!,
                  "capital"
                )}`}
              </p>
            </div>
            <GlobalIcon iconName="ChevronRight" size="XS" color="#424242" />
          </div>
        </div>
      </div>
      <p className="mb-[24px] BodyM font-medium text-[#424242]">
        {translate("beneficiaries.questions.personalData")}
      </p>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-x-[24px] gap-y-[48px]">
        <InputDS
          label={translate("beneficiaries.questions.name.label")}
          placeholder={translate("beneficiaries.questions.name.placeholder")}
          value={beneficiaryForm.name}
          error={beneficiaryFormError.name}
          onBlur={() => clearBeneficiaryError("name")}
          onChange={(e) => updateBeneficiaryForm("name", e.target.value)}
        />
        <InputDS
          label={translate("beneficiaries.questions.lastname.label")}
          placeholder={translate(
            "beneficiaries.questions.lastname.placeholder"
          )}
          value={beneficiaryForm.lastName}
          error={beneficiaryFormError.lastName}
          onBlur={() => clearBeneficiaryError("lastName")}
          onChange={(e) => updateBeneficiaryForm("lastName", e.target.value)}
        />
        <InputDS
          label={translate("beneficiaries.questions.zipCode.label")}
          placeholder={translate("beneficiaries.questions.zipCode.placeholder")}
          value={beneficiaryForm.fiscalCode}
          error={beneficiaryFormError.fiscalCode}
          onBlur={() => clearBeneficiaryError("fiscalCode")}
          onChange={(e) => updateBeneficiaryForm("fiscalCode", e.target.value)}
        />
        <SelectDS
          label={translate("beneficiaries.questions.gender.label")}
          placeholder={translate("beneficiaries.questions.selectOption")}
          options={genderOptions}
          value={beneficiaryForm.gender ?? ""}
          error={beneficiaryFormError.gender}
          onBlur={() => clearBeneficiaryError("gender")}
          onChange={(e) =>
            updateBeneficiaryForm(
              "gender",
              e.target.value as BeneficiaryFormI["gender"]
            )
          }
        />
        <InputDS
          label={translate("beneficiaries.questions.birthdate.label")}
          type="date"
          value={beneficiaryForm.dateOfBirth}
          error={beneficiaryFormError.dateOfBirth}
          onBlur={() => clearBeneficiaryError("dateOfBirth")}
          onChange={(e) => updateBeneficiaryForm("dateOfBirth", e.target.value)}
        />
        <InputDS
          label={translate("beneficiaries.questions.email.label")}
          placeholder={translate("beneficiaries.questions.email.placeholder")}
          value={beneficiaryForm.email}
          error={beneficiaryFormError.email}
          onBlur={() => clearBeneficiaryError("email")}
          onChange={(e) => updateBeneficiaryForm("email", e.target.value)}
        />
        <SelectDS
          label={translate("beneficiaries.questions.countryBirth.label")}
          placeholder={translate("beneficiaries.questions.selectOption")}
          options={countryList}
          value={beneficiaryForm.countryOfBirth ?? ""}
          error={beneficiaryFormError.countryOfBirth}
          onBlur={() => clearBeneficiaryError("countryOfBirth")}
          onChange={(e) =>
            updateBeneficiaryForm("countryOfBirth", e.target.value)
          }
        />
        {beneficiaryForm.countryOfBirth === "IT" ? (
          <PredictiveSearchDS
            label={translate("beneficiaries.questions.cityBirth.label")}
            placeholder={translate("beneficiaries.questions.selectOption")}
            options={cityList}
            value={beneficiaryForm.cityOfBirth ?? ""}
            error={beneficiaryFormError.cityOfBirth}
            onBlur={() => clearBeneficiaryError("cityOfBirth")}
            onChange={(value) => updateBeneficiaryForm("cityOfBirth", value)}
            sortOptionsByValue={true}
          />
        ) : (
          <InputDS
            label={translate("beneficiaries.questions.cityBirth.label")}
            placeholder={translate(
              "beneficiaries.questions.cityBirth.placeholder"
            )}
            value={beneficiaryForm.cityOfBirth}
            error={beneficiaryFormError.cityOfBirth}
            onBlur={() => clearBeneficiaryError("cityOfBirth")}
            onChange={(e) =>
              updateBeneficiaryForm("cityOfBirth", e.target.value)
            }
            disabled={!!!beneficiaryForm.countryOfBirth}
            helper={
              beneficiaryForm.countryOfBirth
                ? ""
                : translate("beneficiaries.questions.cityBirth.helper")
            }
          />
        )}
      </div>
      <p className="mb-[24px] BodyM font-medium text-[#424242] mt-[48px]">
        {translate("beneficiaries.questions.residentialAddress")}
      </p>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-x-[24px] gap-y-[48px]">
        <div className="md:col-span-2">
          <SelectDS
            label={translate("beneficiaries.questions.residenceCountry.label")}
            placeholder={translate("beneficiaries.questions.selectOption")}
            options={countryList}
            value={beneficiaryForm.residentialAddress?.country ?? ""}
            error={beneficiaryFormError.residentialAddress?.country}
            onBlur={() =>
              clearBeneficiaryError("residentialAddress", "country")
            }
            onChange={(e) =>
              updateBeneficiaryForm("residentialAddress", {
                ...beneficiaryForm.residentialAddress,
                country: e.target.value,
                city: "",
              })
            }
          />
        </div>
        <AutocompleteGoogleDS
          domain={DOMAIN!}
          value={beneficiaryForm.residentialAddress?.streetName}
          setValue={(value: string) => {
            updateBeneficiaryForm("residentialAddress", {
              ...beneficiaryForm.residentialAddress,
              streetName: value,
            });
            clearBeneficiaryError("residentialAddress", "streetName");
          }}
          onSelect={(place) => {
            const { number, direction, postalCode } = place;
            updateBeneficiaryForm("residentialAddress", {
              ...beneficiaryForm.residentialAddress,
              streetName: direction,
              streetNo: number,
              zipCode: postalCode,
            });
            clearBeneficiaryError("residentialAddress", "streetName");
          }}
          label={translate("beneficiaries.questions.residenceStreet.label")}
          placeholder={translate(
            "beneficiaries.questions.residenceStreet.placeholder"
          )}
          error={beneficiaryFormError.residentialAddress?.streetName}
        />
        <InputDS
          label={translate("beneficiaries.questions.residenceStreetNo.label")}
          placeholder={translate(
            "beneficiaries.questions.residenceStreetNo.placeholder"
          )}
          value={beneficiaryForm.residentialAddress?.streetNo}
          error={beneficiaryFormError.residentialAddress?.streetNo}
          onBlur={() => clearBeneficiaryError("residentialAddress", "streetNo")}
          onChange={(e) =>
            updateBeneficiaryForm("residentialAddress", {
              ...beneficiaryForm.residentialAddress,
              streetNo: e.target.value,
            })
          }
        />
        {beneficiaryForm.residentialAddress?.country === "IT" ? (
          <PredictiveSearchDS
            label={translate("beneficiaries.questions.residenceCity.label")}
            placeholder={translate("beneficiaries.questions.selectOption")}
            options={cityList}
            value={beneficiaryForm.residentialAddress?.city ?? ""}
            error={beneficiaryFormError.residentialAddress?.city}
            onBlur={() => clearBeneficiaryError("residentialAddress", "city")}
            onChange={(value) =>
              updateBeneficiaryForm("residentialAddress", {
                ...beneficiaryForm.residentialAddress,
                city: value,
              })
            }
            sortOptionsByValue={true}
          />
        ) : (
          <InputDS
            label={translate("beneficiaries.questions.residenceCity.label")}
            placeholder={translate(
              "beneficiaries.questions.residenceCity.placeHolder"
            )}
            value={beneficiaryForm.residentialAddress?.city}
            error={beneficiaryFormError.residentialAddress?.city}
            onBlur={() => clearBeneficiaryError("residentialAddress", "city")}
            onChange={(e) =>
              updateBeneficiaryForm("residentialAddress", {
                ...beneficiaryForm.residentialAddress,
                city: e.target.value,
              })
            }
            helper={
              !beneficiaryForm.residentialAddress?.country
                ? translate("beneficiaries.questions.residenceCity.helper")
                : ""
            }
            disabled={!beneficiaryForm.residentialAddress?.country}
          />
        )}
        <InputDS
          label={translate("beneficiaries.questions.residenceZipcode.label")}
          placeholder={translate(
            "beneficiaries.questions.residenceZipcode.placeholder"
          )}
          value={beneficiaryForm.residentialAddress?.zipCode}
          error={beneficiaryFormError.residentialAddress?.zipCode}
          onBlur={() => clearBeneficiaryError("residentialAddress", "zipCode")}
          onChange={(e) =>
            updateBeneficiaryForm("residentialAddress", {
              ...beneficiaryForm.residentialAddress,
              zipCode: e.target.value,
            })
          }
        />
        <p className="BodyM text-[#424242]">
          {translate("beneficiaries.questions.sameResidenceAsDomicile")}
        </p>
        <div>
          <SingleSelect
            options={sameResidenceOptions}
            onValueChange={(e) => {
              updateBeneficiaryForm("domicileSameAsResidence", e);
              clearBeneficiaryError("domicileSameAsResidence");
            }}
            defaultSize="S"
            name=""
            initialValue={beneficiaryForm.domicileSameAsResidence}
          />
          {beneficiaryFormError.domicileSameAsResidence ? (
            <div className="flex flex-row gap-[4px] BodyS text-[#EA5F5F] mt-[8px]">
              <GlobalIcon
                iconName="AlertCircleIcon"
                size="XXXS"
                color="currentColor"
                className="w-[16px] h-[16px] mt-[2px]"
              />
              <span className="flex-1">
                {beneficiaryFormError.domicileSameAsResidence}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {beneficiaryForm.domicileSameAsResidence === "no" ? (
        <>
          <p className="mb-[24px] BodyM font-medium text-[#424242] mt-[48px]">
            {translate("beneficiaries.questions.domicileAddress")}
          </p>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-[24px] gap-y-[48px]">
            <div className="md:col-span-2">
              <SelectDS
                label={translate(
                  "beneficiaries.questions.residenceCountry.label"
                )}
                placeholder={translate("beneficiaries.questions.selectOption")}
                options={countryList}
                value={beneficiaryForm.domicileAddress?.country ?? ""}
                error={beneficiaryFormError.domicileAddress?.country}
                onBlur={() =>
                  clearBeneficiaryError("domicileAddress", "zipCode")
                }
                onChange={(e) =>
                  updateBeneficiaryForm("domicileAddress", {
                    ...beneficiaryForm.domicileAddress,
                    country: e.target.value,
                    city: "",
                  })
                }
              />
            </div>
            <AutocompleteGoogleDS
              domain={DOMAIN!}
              value={beneficiaryForm.domicileAddress?.streetName}
              setValue={(value: string) => {
                updateBeneficiaryForm("domicileAddress", {
                  ...beneficiaryForm.domicileAddress,
                  streetName: value,
                });
                clearBeneficiaryError("domicileAddress", "streetName");
              }}
              onSelect={(place) => {
                const { number, direction, postalCode } = place;
                updateBeneficiaryForm("domicileAddress", {
                  ...beneficiaryForm.domicileAddress,
                  streetName: direction,
                  streetNo: number,
                  zipCode: postalCode,
                });
                clearBeneficiaryError("domicileAddress", "streetName");
              }}
              label={translate("beneficiaries.questions.residenceStreet.label")}
              placeholder={translate(
                "beneficiaries.questions.residenceStreet.placeholder"
              )}
              error={beneficiaryFormError.domicileAddress?.streetName}
            />
            <InputDS
              label={translate(
                "beneficiaries.questions.residenceStreetNo.label"
              )}
              placeholder={translate(
                "beneficiaries.questions.residenceStreetNo.placeholder"
              )}
              value={beneficiaryForm.domicileAddress?.streetNo}
              error={beneficiaryFormError.domicileAddress?.streetNo}
              onBlur={() => clearBeneficiaryError("domicileAddress", "zipCode")}
              onChange={(e) =>
                updateBeneficiaryForm("domicileAddress", {
                  ...beneficiaryForm.domicileAddress,
                  streetNo: e.target.value,
                })
              }
            />
            {beneficiaryForm.domicileAddress?.country === "IT" ? (
              <PredictiveSearchDS
                label={translate("beneficiaries.questions.residenceCity.label")}
                placeholder={translate("beneficiaries.questions.selectOption")}
                options={cityList}
                value={beneficiaryForm.domicileAddress?.city ?? ""}
                error={beneficiaryFormError.domicileAddress?.city}
                onBlur={() => clearBeneficiaryError("domicileAddress", "city")}
                onChange={(value) =>
                  updateBeneficiaryForm("domicileAddress", {
                    ...beneficiaryForm.domicileAddress,
                    city: value,
                  })
                }
                sortOptionsByValue={true}
              />
            ) : (
              <InputDS
                label={translate("beneficiaries.questions.residenceCity.label")}
                placeholder={translate(
                  "beneficiaries.questions.residenceCity.placeHolder"
                )}
                value={beneficiaryForm.domicileAddress?.city}
                error={beneficiaryFormError.domicileAddress?.city}
                onBlur={() => clearBeneficiaryError("domicileAddress", "city")}
                onChange={(e) =>
                  updateBeneficiaryForm("domicileAddress", {
                    ...beneficiaryForm.domicileAddress,
                    city: e.target.value,
                  })
                }
                helper={
                  !beneficiaryForm.domicileAddress?.country
                    ? translate("beneficiaries.questions.residenceCity.helper")
                    : ""
                }
                disabled={!beneficiaryForm.domicileAddress?.country}
              />
            )}
            <InputDS
              label={translate(
                "beneficiaries.questions.residenceZipcode.label"
              )}
              placeholder={translate(
                "beneficiaries.questions.residenceZipcode.placeholder"
              )}
              value={beneficiaryForm.domicileAddress?.zipCode}
              error={beneficiaryFormError.domicileAddress?.zipCode}
              onBlur={() => clearBeneficiaryError("domicileAddress", "zipCode")}
              onChange={(e) =>
                updateBeneficiaryForm("domicileAddress", {
                  ...beneficiaryForm.domicileAddress,
                  zipCode: e.target.value,
                })
              }
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default BeneficiaryForm;

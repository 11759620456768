export type errorsTerzo = "nameError" | "lastNameError" | "fiscalCodeError" | "genderError" | "dateOfBirthError" | "countryOfBirthError" | "cityOfBirthError" | "emailError" | "streetError" | "streetNumberError" | "cityError" | "zipCodeError" | "countryError" | "CodiceFiscaleError";

export const parseErrors = {
    "nameError":"name",
    "lastNameError":"lastName",
    "fiscalCodeError":"fiscalCode",
    "genderError":"gender",
    "dateOfBirthError":"dateOfBirth",
    "countryOfBirthError":"countryOfBirth",
    "cityOfBirthError":"cityOfBirth",
    "emailError":"email",
    "streetError":"street",
    "streetNumberError":"streetNumber",
    "cityError":"city",
    "zipCodeError":"zipCode",
    "countryError":"country",
    "CodiceFiscaleError": "fiscalCode"
};

export const beneficiariesOptions = [
    {
        value: "Legal heirs",
        label: "questions.beneficiaries.legal"
    },
    {
        value: "My children",
        label: "questions.beneficiaries.children"
    },
    {
        value: "Testament heirs",
        label: "questions.beneficiaries.testament"
    },
    {
        value: "My spouse",
        label: "questions.beneficiaries.spouse"
    },
    {
        value: "Free designation",
        label: "questions.beneficiaries.freeDesignation"
    }
];

export const optionsTerzo = [
    {
        value: "yes",
        text: "Sì",
        label: "Sì",
    },
    {
        value: "no",
        text: "No",
        label: "No",
    },
];
import {
  ButtonDS,
  GlobalIcon,
  IconButton,
  InputDS,
  SelectCustomDS,
  SingleSelect,
} from "get-life-storybook-ts";
import { useContext, useEffect, useState } from "react";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import numberFormat from "../../utils/Utils";
import { BeneficiaryITContext } from "../../context/BeneficiaryIT.context";
import BeneficiaryForm from "./BeneficiaryForm";
import DeleteBeneficiaryModal from "./DeleteBeneficiaryModal";
import { optionsTerzo } from "./constBeneficiaries";
import HttpBeneficiariesRepository from "../../api/request/Beneficiaries/Beneficiaries.service";

const BeneficiariesScreen = () => {
  const {
    translate,
    currentBeneficiary,
    updateCurrentBeneficiaryCapital,
    beneficiariesList,
    submitBeneficiaryForm,
    loading,
    isEditMode,
    setIsEditMode,
    editBeneficiary,
    confirmBeneficiaries,
    acceptUserCommunications,
    setAcceptUserCommunications,
    setBeneficiaryForm,
    beneficiaryForm,
    isEditingCapital,
  } = useContext(BeneficiaryITContext);
  const { leadId, token } = useContext(GetlifeContext);

  const beneficiariesRepository = new HttpBeneficiariesRepository(
    leadId,
    token
  );

  const [enableInput, setEnableInput] = useState<boolean>(false);
  const [totalCapital, setTotalCapital] = useState<number | undefined>(
    undefined
  );
  const [percentages, setPercentages] = useState<
    {
      capital: number;
      percentage: number;
    }[]
  >([]);
  const [maxPercentage, setMaxPercentage] = useState<number>(100);
  const [minPercentage, setMinPercentage] = useState<number>(1);
  const [deleteId, setDeleteId] = useState<string>("");

  const getCapitalOptions = () => [
    ...percentages.map((p) => {
      return {
        label: `${p.percentage}% <span style='color: #8E8E8E'>= ${numberFormat(
          p.capital,
          "price"
        )}</span>`,
        value: p.percentage.toString(),
      };
    }),
    {
      label: translate("beneficiaries.questions.otherAmount"),
      value: "other",
    },
  ];

  const getAssignedCapitalMessage = () => {
    if (beneficiariesList.length > 0 || isAllCapitalAssigned) {
      let message = translate(
        "questions.beneficiaries.capitalBeignAssined"
      ).replace("{{capital}}", numberFormat(totalCapital!, "capital"));
      beneficiariesList.forEach((b, idx) => {
        const counter = idx + 1;
        if (counter === beneficiariesList.length) {
          message += translate("questions.beneficiaries.capitalAnd");
        } else if (counter !== 1) {
          message += ",";
        }
        message +=
          translate("questions.beneficiaries.capitalBeneficiary").replace(
            "{{percentage}}",
            b.percentage.toString()
          ) + counter;
      });
      if (isAllCapitalAssigned) {
        message += translate("questions.beneficiaries.totalCapitalAssigned");
      } else {
        message += translate(
          "questions.beneficiaries.capitalPercentageLeft"
        ).replace("{{percentageLeft}}", maxPercentage.toString());
      }
      return message;
    }

    if (currentBeneficiary.percentage && currentBeneficiary.capital) {
      return translate("questions.beneficiaries.capitalBeignAssined")
        .replace("{{capital}}", numberFormat(totalCapital!, "capital"))
        .replace("{{percentage}}", currentBeneficiary.percentage.toString());
    }
    return translate("questions.beneficiares.capitalToBeAssigned").replace(
      "{{capital}}",
      numberFormat(totalCapital!, "capital")
    );
  };

  const getPercentageCapital = (percentage: number) => {
    if (totalCapital) {
      const _capital = (totalCapital / 100) * percentage;
      return _capital;
    }
    return 0;
  };

  const getIsAllCapitalAssigned = () => {
    let totalPercentage = 0;
    beneficiariesList.forEach((b) => {
      const { percentage } = b;
      totalPercentage += percentage;
    });
    return totalPercentage >= 100;
  };

  const goBackToBeneficiariesScreen = () => {
    setBeneficiaryForm({});
    setIsEditMode(false);
  };

  useEffect(() => {
    (async () => {
      const response =
        await beneficiariesRepository.getBeneficiariesAvailableCapital();
      setTotalCapital(response.capital);
      setPercentages(response.percentages);
      if (response.percentages.length > 0) {
        setMaxPercentage(response.max.percentage);
        setMinPercentage(1);
      } else {
        setMaxPercentage(0);
        setMinPercentage(0);
      }
    })();
  }, [beneficiariesList]);

  useEffect(() => {
    (async () => {
      if (beneficiaryForm.beneficiaryId) {
        const response =
          await beneficiariesRepository.getBeneficiaryAvailableCapital(
            beneficiaryForm.beneficiaryId
          );
        setTotalCapital(response.capital);
        setPercentages(response.percentages);
        setMaxPercentage(response.max.percentage);
        setMinPercentage(1);
      }
    })();
  }, [beneficiaryForm]);

  useEffect(() => {
    if (enableInput) {
      const input = document.getElementById("percentage-input-ref");
      if (input) input.focus();
    }
  }, [enableInput]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [isEditMode]);

  const isAllCapitalAssigned = getIsAllCapitalAssigned();
  const isPercentageAssigned = currentBeneficiary.percentage;

  return (
    <>
      <div className="flex flex-col items-center md:max-w-[540px] max-w-full w-full md:px-[unset] px-[24px]">
        <p className="H4 text-[#424242] font-bold py-[8px]">
          {translate("questions.beneficiaries.title.handleInformation")}
        </p>
        {!isEditMode ? (
          <>
            {beneficiariesList.length > 0 ? (
              <div className="w-full">
                {beneficiariesList.map((b, idx) => {
                  const number = idx + 1;
                  const { fullName, capital, percentage, id } = b;
                  return (
                    <div className="py-[24px] flex flex-row items-center gap-[8px]">
                      <GlobalIcon
                        iconName="SimpleUserIcon"
                        color="var(--primary-color)"
                        size="XS"
                      />
                      <div className="flex-1">
                        <span className="block text-[#7B7B7B] font-medium BodyS text-[12px]">{`${translate(
                          "questions.beneficiaries.beneficiary"
                        )} ${number}`}</span>
                        <div className="flex flex-row items-center flex-1">
                          <div className="flex flex-row gap-[16px] flex-1">
                            <p className="BodyL text-[#424242] font-medium">
                              {fullName}
                            </p>
                            <p className="BodyL text-[var(--primary-color)] font-bold">
                              {`${percentage}% = ${numberFormat(
                                capital,
                                "capital"
                              )}`}
                            </p>
                          </div>
                          <div className="flex flex-row items-center">
                            <IconButton
                              icon="TrashIcon"
                              size="32"
                              buttonType="secondary"
                              className="mr-[16px]"
                              onClick={() => {
                                setDeleteId(id);
                              }}
                              disabled={loading}
                            />
                            <IconButton
                              icon="PencilIcon"
                              size="32"
                              buttonType="primary"
                              className="mr-[24px]"
                              onClick={() => editBeneficiary(id)}
                              disabled={loading}
                            />
                            <GlobalIcon
                              iconName="ChevronRight"
                              size="XS"
                              color="#424242"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {isAllCapitalAssigned ? (
              <div className="grid md:grid-cols-2 gap-[24px] py-[24px]">
                <p className="BodyM text-[#424242]">
                  {translate("beneficiaries.questions.allowCommunication")}
                </p>
                <SingleSelect
                  options={optionsTerzo}
                  onValueChange={(e) => setAcceptUserCommunications(e as any)}
                  dataCy="terzo-question"
                  defaultSize="S"
                  name="terzo_question"
                />
              </div>
            ) : (
              <div className="py-[24px] flex flex-col gap-[8px] max-w-full">
                <div className="flex flex-row items-center gap-[8px] py-[12px]">
                  <GlobalIcon
                    iconName="SimpleUserIcon"
                    color="var(--primary-color)"
                    size="XS"
                  />
                  <p className="BodyL font-medium text-[#424242]">
                    {translate("questions.beneficiaries.beneficiary")}{" "}
                    {beneficiariesList.length + 1}
                  </p>
                </div>
                {enableInput ? (
                  <InputDS
                    label={translate("questions.beneficiares.assign.capital")}
                    onChange={(event) => {
                      let value = parseInt(event.target.value);
                      if (value > maxPercentage) value = maxPercentage;
                      updateCurrentBeneficiaryCapital({
                        capital: getPercentageCapital(value),
                        percentage: value,
                      });
                    }}
                    type="number"
                    value={(() => {
                      if (currentBeneficiary.percentage) {
                        const percentageInt = parseInt(
                          currentBeneficiary.percentage.toString()
                        );
                        if (isNaN(percentageInt)) return "";
                        return percentageInt;
                      }
                      return "";
                    })()}
                    onBlur={(event) => {
                      let value = parseInt(event.target.value);
                      if (value > maxPercentage) value = maxPercentage;
                      if (value < minPercentage) value = minPercentage;
                      updateCurrentBeneficiaryCapital({
                        capital: getPercentageCapital(value),
                        percentage: value,
                      });
                    }}
                    icon="PercentageIcon"
                    placeholder={translate(
                      "questions.beneficiares.assign.capital.placeholder"
                    )}
                    helper={translate(
                      "questions.beneficiares.assign.capital.helper"
                    )}
                    min={minPercentage}
                    max={maxPercentage}
                    id="percentage-input-ref"
                  />
                ) : (
                  <SelectCustomDS
                    label={translate("questions.beneficiares.assign.capital")}
                    onChange={(value) => {
                      if (value === "other") {
                        updateCurrentBeneficiaryCapital({
                          capital: undefined,
                          percentage: undefined,
                        });
                        setEnableInput(true);
                      } else {
                        const _value = parseInt(value);
                        const _capital = percentages.find(
                          (p) => p.percentage === _value
                        )?.capital!;
                        updateCurrentBeneficiaryCapital({
                          capital: _capital,
                          percentage: _value,
                        });
                      }
                    }}
                    options={getCapitalOptions()}
                    value={currentBeneficiary.percentage?.toString() ?? ""}
                    icon="PercentageIcon"
                    placeholder={translate(
                      "questions.beneficiares.assign.capital.placeholder"
                    )}
                    helper={translate(
                      "questions.beneficiares.assign.capital.helper"
                    )}
                  />
                )}
              </div>
            )}
          </>
        ) : null}
        {isEditMode ? (
          <BeneficiaryForm
            totalCapital={totalCapital}
            minPercentage={minPercentage}
            maxPercentage={maxPercentage}
            percentages={percentages}
          />
        ) : null}
        <div
          className="flex flex-row gap-[8px] rounded-[8px] p-[16px] border-2 my-[24px]"
          style={{
            backgroundColor: isAllCapitalAssigned
              ? "#E6F3F0"
              : "var(--light-primary-color)",
            borderColor: isAllCapitalAssigned
              ? "#018565"
              : "var(--primary-color)",
          }}
        >
          {isAllCapitalAssigned ? (
            <GlobalIcon
              iconName="CircleCheckFilledIcon"
              color="#018565"
              size="XS"
            />
          ) : (
            <GlobalIcon
              iconName="InfoIcon"
              color="var(--primary-color)"
              size="XS"
            />
          )}
          <p
            className="flex-1 text-[#424242] BodyS font-medium"
            dangerouslySetInnerHTML={{
              __html: getAssignedCapitalMessage(),
            }}
          ></p>
        </div>
        <div className="py-[48px] flex flex-row-reverse gap-[16px] justify-center">
          {isEditMode ? (
            <ButtonDS
              label={translate("beneficiaries.questions.confirmAndSave")}
              rightIcon="ArrowRightIcon"
              className="w-[253px]"
              disabled={loading || isEditingCapital}
              onClick={submitBeneficiaryForm}
            />
          ) : isAllCapitalAssigned ? (
            <ButtonDS
              label={translate("beneficiaries.questions.confirmAndContinue")}
              rightIcon="ArrowRightIcon"
              disabled={loading || acceptUserCommunications === ""}
              onClick={() => confirmBeneficiaries()}
            />
          ) : (
            <ButtonDS
              label={translate("beneficiaries.questions.continue")}
              rightIcon="ArrowRightIcon"
              className="w-[253px]"
              onClick={() => setIsEditMode(true)}
              disabled={!isPercentageAssigned || loading}
            />
          )}
          {isEditMode ? (
            <ButtonDS
              label={translate("beneficiaries.questions.backwards")}
              leftIcon="ArrowLeft"
              buttonType="secondary"
              ghost
              disabled={loading}
              onClick={goBackToBeneficiariesScreen}
            />
          ) : null}
        </div>
      </div>
      <DeleteBeneficiaryModal deleteId={deleteId} setDeleteId={setDeleteId} />
    </>
  );
};

export default BeneficiariesScreen;

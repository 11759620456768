import { delet, get, patch, post, put } from "../../core/HTTPService.service";

export default class HttpGlobal {
  public readonly header: { Authorization: string } | undefined;
  public readonly leadId: string | undefined;

  constructor(leadId?: string, token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
    this.leadId = leadId;
  }

  public generalGet = async (url: string, mode?: RequestMode) => {
    let data: any;
    try {
      const response = await get({ path: url, headers: this.header, mode });
      data = response;
    } catch (error) {
      throw error;
    }
    return data;
  };

  public generalPost = async ({
    url,
    dataRequest,
    header = this.header,
    mode,
  }: any) => {
    let data: any;
    try {
      const response = await post({
        path: url,
        body: dataRequest,
        headers: header,
        mode,
      });
      data = response;
    } catch (error) {
      throw error;
    }

    return data;
  };

  public generalPut = async ({
    url,
    dataRequest,
    header = this.header,
    mode,
  }: any) => {
    let data: any;
    try {
      const response = await put({
        path: url,
        body: dataRequest,
        headers: header,
        mode,
      });
      data = response;
    } catch (error) {
      throw error;
    }

    return data;
  };

  public generalPatch = async ({
    url,
    dataRequest,
    header = this.header,
    mode,
  }: any) => {
    let data: any;
    try {
      const response = await patch({
        path: url,
        body: dataRequest,
        headers: header,
        mode,
      });
      data = response;
    } catch (error) {
      throw error;
    }

    return data;
  };

  public generalDelete = async ({
    url,
    dataRequest = {},
    header = this.header,
    mode,
  }: any) => {
    let data: any;
    try {
      const response = await delet({
        path: url,
        body: dataRequest,
        headers: header,
        mode,
      });
      data = response;
    } catch (error) {
      throw error;
    }

    return data;
  };
}

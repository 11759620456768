import { useContext } from "react";
import { BeneficiaryITContext } from "../../context/BeneficiaryIT.context";
import {
  ButtonDS,
  CheckboxAtom,
  InputDS,
  PredictiveSearchDS,
  RadioButton,
  SelectDS,
  SingleSelect,
} from "get-life-storybook-ts";
import AutocompleteGoogle from "get-life-storybook-ts/lib/components/Inputs/AutoCompleteGoogle/AutoCompleteGoogle";
import { beneficiariesOptions, optionsTerzo } from "./constBeneficiaries";

const SelectTypeBeneficiaries = () => {
  const {
    translate,
    beneficiaryType,
    setBeneficiaryType,
    checkedConditions,
    setCheckedConditions,
    showTerzo,
    setShowTerzo,
    terzoReferente,
    setTerzoReferente,
    cityList,
    countryList,
    saveBeneficiaryType,
    terzoError,
  } = useContext(BeneficiaryITContext);

  return (
    <>
      <div className="flex flex-col items-center max-w-[540px] md:px-[unset] px-[24px]">
        <div className="pb-[30px] flex items-center">
          <div className="flex flex-row items-center w-full">
            <span className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em] font-bold font-DM undefined text-left flex !flex-row w-full gap-2 text-[22px] ">
              {translate("coverage.beneficiary.question")}
            </span>
          </div>
        </div>
        <div className="flex items-center w-full flex-col-reverse gap-6  w-full">
          <div className="w-full">
            <div className="flex flex-col items-center">
              <div className="w-full flex flex-col gap-4">
                {beneficiariesOptions.map((option) => (
                  <div>
                    <RadioButton
                      variant="Radio"
                      label={translate(option.label)}
                      value={option.value}
                      size="S"
                      onChange={() => setBeneficiaryType(option.value)}
                      checked={beneficiaryType === option.value}
                    />
                  </div>
                ))}
              </div>
              <div className="w-full mt-[8px]">
                <CheckboxAtom
                  isLegal
                  checked={checkedConditions}
                  label={
                    <span
                      className="text-[14px] leading-[160%] tracking-[-0.02em] font-normal
                                    font-DM text-dark-gray-100 text-body-md"
                    >
                      {translate("questions.beneficiaries.checkbox")}
                    </span>
                  }
                  onValueChange={setCheckedConditions}
                  className="py-4 px-0"
                />
              </div>
              <div className="w-full">
                <div className="border-t border-gray-2 pt-[32px] pb-[40px] mt-[24px]">
                  <span className="font-bold text-[22px] text-[#424242] text-left">
                    Terzo referente
                  </span>

                  <div className="grid grid-cols-1 md:grid-cols-[1fr,258px] gap-[24px] mt-[32px] items-center">
                    <span className="text-[16px] leading-[160%] tracking-[-0.02em] font-normal font-DM text-[#424242] text-left">
                      Hai esigenze specifiche di riservatezza e intendi nominare
                      un referente terzo (incaricato) diverso dal beneficiario,
                      a cui la Società può fare riferimento in caso di decesso?
                    </span>
                    <div className="w-[258px] [&>*>*]:gap-[24px]">
                      <SingleSelect
                        options={optionsTerzo}
                        onValueChange={(e) => setShowTerzo(e as any)}
                        dataCy="terzo-question"
                        defaultSize="S"
                        name="terzo_question"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                {showTerzo === "yes" && (
                  <div>
                    <span className="BodyS text-left mt-2 text-[#424242]">
                      Il Contraente desidera nominare il seguente referente
                      terzo, diverso dal beneficiario, a cui la Società può far
                      riferimento in caso di decesso dell’Assicurato:
                    </span>
                    <div className="form-terzo grid md:grid-cols-2 gap-[24px]">
                      <span className="BodyS font-medium text-left mt-2 md:col-span-2">
                        Dati personali
                      </span>
                      <InputDS
                        label={"Nome"}
                        onChange={(event) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            name: event.target.value,
                          })
                        }
                        value={terzoReferente?.name}
                        placeholder="Mario"
                        data-cy="name-terzo-input"
                        error={terzoError?.name?.message}
                      />

                      <InputDS
                        label={"Cognome"}
                        onChange={(event) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            lastName: event.target.value,
                          })
                        }
                        value={terzoReferente?.lastName}
                        data-cy="lastname-terzo-input"
                        placeholder="Rossi"
                        error={terzoError?.lastName?.message}
                      />
                      <InputDS
                        label={"Codice fiscale"}
                        onChange={(event) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            fiscalCode: event.target.value,
                          })
                        }
                        value={terzoReferente?.fiscalCode}
                        data-cy="dni-terzo-input"
                        placeholder="RSSMRA80A01H501U"
                        error={terzoError?.fiscalCode?.message}
                      />

                      <SelectDS
                        options={[
                          {
                            value: "M",
                            label: "Maschio",
                          },
                          {
                            value: "F",
                            label: "Femmina",
                          },
                        ]}
                        label={"Sesso"}
                        onChange={(event) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            gender: event.target.value,
                          })
                        }
                        value={terzoReferente?.gender}
                        data-cy="gender-terzo-input"
                        placeholder="Selezionare un'opzione"
                        error={terzoError?.gender?.message}
                      />
                      <InputDS
                        type="date"
                        value={terzoReferente?.dateOfBirth}
                        onChange={(event) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            dateOfBirth: event.target.value,
                          })
                        }
                        label={"Data di nascita"}
                        data-cy="birthdate-input"
                        error={terzoError?.dateOfBirth?.message}
                      />

                      <PredictiveSearchDS
                        label={"Nazione di nascita"}
                        placeholder="Seleziona un'opzione"
                        options={countryList}
                        value={terzoReferente?.countryOfBirth}
                        data-cy="countryOfBirth-terzo-input"
                        onChange={(value) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            countryOfBirth: value,
                          })
                        }
                        error={terzoError?.countryOfBirth?.message}
                        sortOptionsByValue={true}
                      />
                      {terzoReferente?.countryOfBirth !== "IT" ? (
                        <InputDS
                          label={"Città di nascita"}
                          onChange={(event) =>
                            setTerzoReferente &&
                            setTerzoReferente({
                              ...terzoReferente!,
                              cityOfBirth: event.target.value,
                            })
                          }
                          value={terzoReferente?.cityOfBirth}
                          data-cy="name-terzo-input"
                          placeholder="Scrivere la città di nascita"
                          error={terzoError?.cityOfBirth?.message}
                        />
                      ) : (
                        <PredictiveSearchDS
                          label={"Città di nascita"}
                          onChange={(value) =>
                            setTerzoReferente &&
                            setTerzoReferente({
                              ...terzoReferente!,
                              cityOfBirth: value,
                            })
                          }
                          value={terzoReferente?.cityOfBirth}
                          data-cy="name-terzo-input"
                          placeholder="Scrivere la città di nascita"
                          options={cityList}
                          error={terzoError?.cityOfBirth?.message}
                          sortOptionsByValue={true}
                        />
                      )}

                      <InputDS
                        label={"E-mail"}
                        onChange={(event) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            email: event.target.value,
                          })
                        }
                        value={terzoReferente?.email}
                        data-cy="lastname-terzo-input"
                        placeholder="Scrivere l'e-mail"
                        error={terzoError?.email?.message}
                      />
                      <span className="BodyS font-medium text-left mt-2 md:col-span-2">
                        Indirizzo di residenza
                      </span>
                      <AutocompleteGoogle
                        DOMAIN="it"
                        handleAddress={(value: string) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            street: value,
                          })
                        }
                        handleZipCode={(value: string) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            zipCode: value,
                          })
                        }
                        handleProvince={(value: string) => value}
                        handleCity={(value: string) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            city: value,
                          })
                        }
                        handleNumber={(value: string) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            streetNumber: value,
                          })
                        }
                        label={"Via"}
                        placeholder="Via"
                        translate={undefined}
                        error={terzoError?.street?.error}
                        errorMessage={terzoError?.street?.message}
                      />
                      <InputDS
                        label={"Numero civico"}
                        onChange={(event) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            streetNumber: event.target.value,
                          })
                        }
                        value={terzoReferente?.streetNumber}
                        data-cy="name-terzo-input"
                        placeholder="Numero civico"
                        error={terzoError?.streetNumber?.message}
                      />
                      <PredictiveSearchDS
                        label={"Città"}
                        onChange={(value) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            city: value,
                          })
                        }
                        value={terzoReferente?.city}
                        data-cy="name-terzo-input"
                        placeholder="Scrivi la città"
                        options={cityList}
                        error={terzoError?.city?.message}
                        sortOptionsByValue={true}
                      />

                      <InputDS
                        label={"CAP"}
                        onChange={(event) =>
                          setTerzoReferente &&
                          setTerzoReferente({
                            ...terzoReferente!,
                            zipCode: event.target.value,
                          })
                        }
                        value={terzoReferente?.zipCode}
                        data-cy="lastname-terzo-input"
                        placeholder="Scrivi il codice postale"
                        error={terzoError?.zipCode?.message}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full flex justify-center py-12">
                <ButtonDS
                  label="Continua"
                  rightIcon="ArrowRightIcon"
                  onClick={() => saveBeneficiaryType()}
                  size="50"
                  className="w-[285px]"
                  disabled={
                    !beneficiaryType || !showTerzo || !checkedConditions
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectTypeBeneficiaries;

import { useContext } from "react";
import { BeneficiaryITContext } from "../../context/BeneficiaryIT.context";
import SelectTypeBeneficiaries from "./SelectTypeBeneficiaries";
import BeneficiariesScreen from "./BeneficiariesScreen";

const BeneficiaryIT = () => {
  const { isSelectBeneficiaryType, beneficiaryType } =
    useContext(BeneficiaryITContext);
  return (
    <>
      {!isSelectBeneficiaryType && <SelectTypeBeneficiaries />}
      {isSelectBeneficiaryType === "Free designation" && (
        <BeneficiariesScreen />
      )}
    </>
  );
};

export default BeneficiaryIT;

import { ButtonDS, GlobalIcon, ModalDS } from "get-life-storybook-ts";
import { useContext, useState } from "react";
import { BeneficiaryITContext } from "../../context/BeneficiaryIT.context";

const DeleteBeneficiaryModal = ({
  deleteId,
  setDeleteId,
}: {
  deleteId: string;
  setDeleteId: (value: string) => void;
}) => {
  const { deleteBeneficiary } = useContext(BeneficiaryITContext);

  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const closeAndClear = () => {
    setDeleteId("");
    setIsDeleted(false);
  };

  return (
    <ModalDS
      open={!!deleteId}
      onClose={closeAndClear}
      title={isDeleted ? "Rimuovere il beneficiario" : "ATTENZIONE"}
      icon={isDeleted ? "TrashXIcon" : "AlertTriangleIcon"}
      content={
        isDeleted ? (
          <div>
            <div className="p-[8px] bg-[var(--light-primary-color)] rounded-full w-fit">
              <GlobalIcon
                iconName="CheckedIcon"
                size="XS"
                color="var(--primary-color)"
              />
            </div>
            <p className="BodyM text-[#424242] mt-[16px]">
              Il beneficiario è stato rimosso con successo. Scompare dall'elenco
              dei beneficiari.
            </p>
          </div>
        ) : (
          <div>
            <p>
              <b>È sicuro di voler rimuovere questo beneficiario?</b>
              <br />
              Assicuratevi che vogliate davvero{" "}
              <b>rimuovere questo beneficiario</b>, poiché si tratta di
              un'azione definitiva: una volta rimosso, scomparirà dall'elenco
              dei beneficiari.
            </p>
          </div>
        )
      }
      buttons={
        isDeleted ? null : (
          <>
            <ButtonDS
              label="No, annullare"
              ghost={true}
              onClick={closeAndClear}
            />
            <ButtonDS
              label="Sì, rimuovere il beneficiario"
              onClick={async () => {
                const wasDeleted = await deleteBeneficiary(deleteId);
                setIsDeleted(wasDeleted);
              }}
            />
          </>
        )
      }
    />
  );
};

export default DeleteBeneficiaryModal;

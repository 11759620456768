import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import FormBuilder from "../../../components/Components/FormBuilder/FormBuilder";
import "../css/questions.scss";
import "./postPayment.scss";
import { prepareDataFromDataLayerCoverage } from "../../../utils/prepareDataFromDataLayer";
import HttpLeadRepository from "../../../api/request/Lead/Lead.service";
import { GetLifeTrackerContext } from "../../../context/GetLifeTracker.context";
import FormButton from "../../../components/Components/FormBuilder/FormButtons";
import { I18nContext } from "../../../context/i18n.context";
import { FormBuilderProvider } from "../../../context/FormBuilder.context";
import { GlobalCountryApi } from "../../../utils/InternationlChanges";
import { DOMAIN } from "../../../constants/Global";

export default function PostPayment(props) {
  const { leadId, token, step, question, showButton, isLoading, setLoading } =
    props;

  const {
    state: { translate },
  } = useContext(I18nContext);

  const navigate = useNavigate();

  const { handleTrackerQuestion } = useContext(GetLifeTrackerContext);

  const [chat, setchat] = useState(false);

  const [answer, setAnswer] = useState(false);

  const [beneficiaries, setBeneficiaries] = useState(null);

  
  const [countryList, setCountryList] = useState([]);

  const leadRepository = new HttpLeadRepository(leadId, token);
  const route = Location.pathname;
  const isOneChoinceQuestion =
    question.type === "radio" &&
    question.componentName &&
    route === "/stepChallenger/4";

  const handleChat = () => setchat(!chat);

  const handleAnswer = (e) => {
    setAnswer(e);
  };

  const sendOnChange = (e) => {
    sendBeneficiaries(e);
  };

  const handleSubmit = () => {
    sendBeneficiaries(beneficiaries);
  };

  const sendBeneficiaries = async (beneficiariesTypes) => {

    const data = {
      beneficiaries: beneficiariesTypes,
    };

      await leadRepository.saveData(data).then(() => {
        if (beneficiariesTypes === "other") {
          navigate("/beneficiaries/1");
        } else if (beneficiariesTypes) {
          navigate("/coverage/7");
        }
      });
    
  };

  const handleBeneficiaries = (e) => {
    setBeneficiaries(e);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (leadId && token) {
      leadRepository.getCheckoutData().then((data) => {
        if (data.code === 401) {
          navigate("/login");
        } else {
          setBeneficiaries(data.lead.beneficiaries);
          const dataLayerCoverage = prepareDataFromDataLayerCoverage(
            data,
            leadId
          );
          handleTrackerQuestion({
            type: "GA",
            data: {
              ecommerce: dataLayerCoverage,
            },
          });
        }
      });
    }

    leadRepository.getQuestionNomenclator(GlobalCountryApi[DOMAIN]).then((res) => {
      const { answers } = res;

      setCountryList(answers);
    });
    
  }, [leadId, token]);

  return (
    <FormBuilderProvider
      answer={step === 1 ? answer : beneficiaries}
      question={question}
    >
      <div className="w-full questions-postPayment">
        <div className={"w-full questions-outerContainer"}>
          <div className={"questions-container"}>
            <div className={`questions-innerContainer ${DOMAIN === "it" ? "questions-innerContainer-it" : ""}`}>
              <FormBuilder
                answer={step === 1 ? answer : beneficiaries}
                avatarStack={true}
                chat={chat}
                handleChange={handleChat}
                handleValues={step === 1 ? handleAnswer : handleBeneficiaries}
                isLoading={isLoading}
                leadId={leadId}
                question={question}
                sendOnChange={sendOnChange}
                handleSubmit={handleSubmit}
                token={token}
                setLoading={setLoading}
                countryList={countryList}
              />
            </div>
            {question.type === "radio" || question.type === "beneficiaries" && (
              <FormButton
                showButton={showButton()}
                isLoading={isLoading}
                isOneChoinceQuestion={isOneChoinceQuestion}
                componentName={question.componentName}
                translate={translate}
                handleSubmit={handleSubmit}
                question={question}
                answer={step === 1 ? answer : beneficiaries}
                questionStack={[]}
                isSticky={false}
              />
            )}
          </div>
        </div>

      </div>
    </FormBuilderProvider>
  );
}

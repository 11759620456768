/* eslint-disable react-hooks/exhaustive-deps */
import FadeIn from "react-fade-in";
import "./formBuilder.scss";

import { Question, QuestionSelector } from "get-life-storybook-ts";
import numberFormat from "../../../utils/Utils";
import FormBuilderController from "./FormBuilder.controller";
import FormType from "./Forms/FormType";
import { DayAvailable, GlobalDomainQuestions, PrivacityLink } from "../../../utils/InternationlChanges";
import { DOMAIN } from "../../../constants/Global";

export const range = ({ initial, final, step }) => {
  return Array.from({ length: (final - initial) / step + 1 }, (_, i) => ({
    label: numberFormat(initial + i * step, "capital"),
    value: initial + i * step,
  }));
};

const TooltipBeneficiaresFr = ({ translate }) => {
  return (
    <div>
      <p>
        <b>{translate("beneficiaries.select.tooltip.standard.strong")} </b>
        {translate("beneficiaries.select.tooltip.standard.first")}
        <br></br>
      </p>
      <br></br>
      <p>
        <b>{translate("beneficiaries.select.tooltip.particular.strong")}</b>
        {translate("beneficiaries.select.tooltip.particular")}
      </p>
    </div>
  );
};

const FormBuilder = ({
  answer,
  capitalLimits,
  chat,
  countryList,
  error,
  handleSubmit,
  handleValues,
  onBlur,
  personalData,
  question,
  callback,
  isOneChoinceQuestionRoute,
  isDisplayed,
  setIsDisplayed,
  setValidateQuestionDateInput,
}) => {
  const {
    answers,
    componentName,
    content,
    placeholder,
    subtitle,
    tooltip,
    type,
    questionId
  } = question;

  const {
    actualUrl,
    checkedConditions,
    disabled,
    domain,
    formError,
    formInputs,
    handleChange,
    initialValue,
    isMobile,
    isOneChoinceQuestion,
    leadId,
    loading,
    options,
    prepareLabel,
    setCheckedConditions,
    supportText,
    token,
    translate,
    typeInputQuestions,
    typeInputsTransform,
    subtitleDate
  } = FormBuilderController({
    answer,
    answers,
    capitalLimits,
    componentName,
    error,
    handleSubmit,
    handleValues,
    isOneChoinceQuestionRoute,
    name: question.name,
    subtitle,
    type,
  });

  const inputProps = {
    callback: isOneChoinceQuestion && callback(),
    checkBoxLabel: translate("productQuestions.label.checkBoxLabel"),
    dateLabel: translate("productQuestions.label.dateLabel"),
    initialError: error || formError,
    initialValue: initialValue(),
    inputMode: typeInputQuestions(componentName),
    invalidDateMessage: translate("productQuestions.label.invalidDateMessage"),
    isDisplayed: isDisplayed,
    isMultiSelect: type === "select_multiple",
    label: prepareLabel,
    labelDateFrom: translate("productQuestions.label.labelDateFrom"),
    labelDatoTo: translate("productQuestions.label.labelDatoTo"),
    locale: GlobalDomainQuestions[domain],
    onBlur: onBlur,
    onEnterKeyPress: !disabled && handleSubmit,
    onValueChange: (e) => handleChange(e),
    options: options(),
    placeholder: placeholder,
    setIsDisplayed: setIsDisplayed,
    setValidateQuestionDateInput: setValidateQuestionDateInput,
    type: typeInputsTransform(type),
    textAcceptDataProcessing: translate("calculatorPage.acceptDataProcessing"),
    textAcceptPolicy: translate("calculatorPage.acceptPolicy"),
    href: PrivacityLink[domain],
    checkedConditions,
    setCheckedConditions,
    startDate: DayAvailable[domain],
    iconName: {
      "height_question": "RulerIcon",
      "weight_question": "ScaleOutlineIcon"
    }[componentName] || undefined,
    countryList: countryList,
  };

const isQuestionSelector = type === "select_multiple";

  return (
    <div className={`formBuilder-container ${DOMAIN === "it" ? "formBuilder-container-it" : ""}`}>
      <FadeIn transitionDuration={1000}>
        <div className={isMobile && isDisplayed ? "" : "formBuilder-form"}>
        {isQuestionSelector && (
              <QuestionSelector
                isMultiSelect
                options={answers}
                placeholder={translate("productQuestions.label.selectOptions")}
                overlayButtonLabel={translate("productQuestions.overlay.button")}
                selectAnOptionText={translate("productQuestions.label.selectAnOption")}
                closeText={translate("productQuestions.label.close")}
                onValueChange={(e) => handleValues(e)}
                helpContent={tooltip && <p>{tooltip}</p>}
                supportText={subtitle}
                query={content}
                checkBoxLabel={translate("productQuestions.label.anotherNotInTheList")}
                setIsDisplayed={setIsDisplayed}
                isDisplayed={isDisplayed}
                initialValue={question.previousIllnessesAnswered}
                selectedText={translate("productQuestions.label.selected")}
                />
            )}
          {!isQuestionSelector && type &&
          !formInputs.includes(type) &&
          componentName !== "external_underwriting" ? (
            <Question
              question={{
                questionId:questionId,
                query: content,
                subtitle:
                  (type === "calendar_extended" && subtitleDate) ||
                  supportText(componentName) ||
                  subtitle,
                tooltip: tooltip && {
                  modalBody:
                    question.name === "beneficiaries" &&
                    domain === "fr" ? (
                      <TooltipBeneficiaresFr translate={translate} />
                    ) : (
                      tooltip
                    ),
                },
              }}
              inputProps={inputProps}
            />
          ) : (
            <FormType
              actualUrl={actualUrl}
              answer={answer}
              answers={answers}
              capitalLimits={capitalLimits}
              componentName={componentName}
              chat={chat}
              content={content}
              countryList={countryList}
              handleSubmit={handleSubmit}
              handleValues={handleValues}
              isLoading={loading}
              leadId={leadId}
              personalData={personalData}
              prepareLabel={prepareLabel}
              subtitle={subtitle}
              token={token}
              tooltip={tooltip}
              translate={translate}
              type={type}
              question={question}
            />
          )}
        </div>
      </FadeIn>
    </div>
  );
};

export default FormBuilder;
